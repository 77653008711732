.gap-wrapper {
  position: relative;

  width: 100%;
  height: min(max(200px, 60vw), 500px);

  background-image: url('../../../media/images/backgrounds/bg_02.jpg');
  background-attachment: fixed;
  background-repeat: repeat-y;
  background-size: 130vw;

  .white-filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
  }

  @media screen and (max-width: 768px) {
    background-size: 150vw;
  }
}
