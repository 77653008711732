:root {
  --base-color-black: rgb(60, 60, 60);
  --base-color-gray: rgb(134, 134, 134);
  --base-color-yellow: rgb(25, 46, 68);
  --base-color-white: rgb(255, 255, 255);

  /////////////////////////////////////////

  --font-black-color: var(--base-color-black);
}
