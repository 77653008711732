.logo-wrapper {
  display: flex;
  width: 100%;
  height: 40vw;
  max-height: 500px;
  min-height: 300px;
  align-items: center;
  justify-content: center;
  background: radial-gradient(#ffffff, #e3e3e3);

  .logo-image {
    margin-top: 75px;
    height: 30vw;
    min-height: 60px;
    max-height: 55%;
  }
}
